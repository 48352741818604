import React from 'react'
import './cardCountdown.scss'
import { useTranslation } from 'react-i18next';

function CardCountdown(props) {
    let {days, hours, minutes, seconds} = props
    const { t } = useTranslation();

    return (
        <div className='card-countdown'>
            <div className='box-countdown'>
                {days}
                <p>{t('day')}</p>
            </div>
            <div className='box-countdown'>
                {hours}
                <p>{t('hour')}</p>
            </div>
            <div className='box-countdown'>
                {minutes}
                <p>{t('minute')}</p>
            </div>
            <div className='box-countdown'>
                {seconds}
                <p>{t('second')}</p>
            </div>
        </div>
    )
}

export default CardCountdown
import React from 'react'
import './opening.scss'
import { Button } from 'react-bootstrap'
import { Gap } from 'components/atoms'
import { useTranslation } from 'react-i18next';

function Opening(props) {
    const { t } = useTranslation();
    let {guestName} = props;
    return (
        <div className='opening'>
            <div className='container'>
                <div>
                    <div className='opening-title'>{t('opening_title')}</div>
                    <Gap height={20}/>
                    <div className='title-name'>Putri & Alvin</div>
                    <div className='date'>25 . 01 . 2025</div>
                    <Gap height={6}/>
                    <div className='opening-tag'>#ALoveYU</div>
                </div>
                <div>
                    <div className='box-guest' style={{display: (guestName === null || guestName === '') ? 'none' : 'block'}}>
                        <p>{t('opening_greeting')}</p>
                        <p>{t('opening_greeting2')}</p>
                        <p className='nama-undangan'>{guestName}</p>
                    </div>
                    <Gap height={30}/>
                    <Button variant='aloveyu' onClick={props.handleOpeningPage}>{t('opening_btn')}</Button>
                </div>
                
            </div>
        </div>
    )
}

export default Opening
import React from 'react'
import './quote.scss'
import { Fade } from 'react-awesome-reveal'

function Quote(props) {
    return (
        <div className={'quote '+ props.type}>
            <Fade duration={1500} delay={300} cascade>
                <div className='title'>{props.title}</div>
                <div>{props.text}</div>
            </Fade>
        </div>
    )
}

export default Quote
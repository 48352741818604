import React from 'react'
import './card-name.scss'
import { Image } from 'react-bootstrap'
import { Gap } from 'components/atoms'
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-awesome-reveal';

function CardName() {
    const { t } = useTranslation();

    return (
        <div className='card-name' id='card-name'>
            <Gap height={30}/>
            <Fade duration={1500} delay={300} cascade triggerOnce>
                <div style={{color: 'black'}}>
                    <Gap height={20}/>
                    <Image src='https://aloveyu.com/images/putri-close-up.webp' height={250} width={250} style={{objectFit:'cover'}}/>
                    <Gap height={12}/>
                    <div className='name'>Asteria Agusti Yuwita Putri</div>
                    <div style={{maxWidth:'400px', margin:'auto'}}>{t('daughter_of')}(&#128327;)</div>
                </div>
            </Fade>
            <Gap height={150}/>
            <Fade duration={1500} delay={300} cascade triggerOnce>
                <div>
                    <Gap height={20}/>
                    <Image src='https://aloveyu.com/images/alvin-close-up.webp' height={250} width={250} style={{objectFit:'cover'}}/>
                    <Gap height={12}/>
                    <div className='name'>Albertus Alvin Adhitama Susanto</div>
                    <div style={{maxWidth:'400px', margin:'auto'}}>{t('son_of')}</div>
                </div>
            </Fade>
            <Gap height={30}/>
        </div>
    )
}

export default CardName
import photos from 'assets/images/photos'
import React, { useState } from 'react'
import PhotoAlbum from 'react-photo-album'
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import './gallery.scss'
import { Gap } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-awesome-reveal';

function Gallery() {
    const [index, setIndex] = useState(-1);
    const {t} = useTranslation();
    return (
        <div id='gallery' className='gallery'>
            <Fade duration={1500} triggerOnce>
                <div className='title'>{t('our_moment')}</div>
                <Gap height={20}/>
                <PhotoAlbum layout='rows' photos={photos} targetRowHeight={175} onClick={({ index: current }) => setIndex(current)} />
                <Lightbox
                    index={index}
                    slides={photos}
                    open={index >= 0}
                    close={() => setIndex(-1)}
                />
                <Gap height={40}/>
            </Fade>
        </div>
    )
}

export default Gallery